import classnames from "classnames";
import Moment from 'moment';
import React from "react";
import { Accordion, Alert, Badge, Button, ButtonGroup, Card, Col, Dropdown, DropdownButton, Figure, ListGroup, Modal, Row } from "react-bootstrap";
import { FaAngleUp, FaAngleDown, FaCalendar, FaCode, FaComment, FaDownload, FaImage, FaMap, FaPlay, FaTrash, FaTimes } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import * as firestoreApi from "../../api/firestoreApi.js";
import * as missionApi from "../../api/missionApi";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import CollectStatusMessage from "../../components/CollectStatusMessage";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import DevMenuButton from "../../components/DevMenuButton";
import MapButton from "../../components/MapButton";
import DroneCollectStatusMessage from "../../components/DroneCollectStatusMessage";
import FeedbackModal from "../../components/FeedbackModal";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import Layout, { MissionBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import LogDevMenuItem from "../../components/LogDevMenuItem";
import AppStatusMessage from "../../components/AppStatusMessage/index.jsx";
import StorageDevMenuItem from "../../components/StorageDevMenuItem";
import ProcessingStatusMessage from "../../components/ProcessingStatusMessage/index.jsx";
import UploadStatusMessage from "../../components/UploadStatusMessage";
import MeasurementInfoString from "../../components/MeasurementInfo"
import { useUser } from "../../contexts/user_provider";
import { getFirebaseStorageDownloadUrl } from "../../corvusFirebase.js";
import urls from "../../urls.js";
import useMissionFlightsSubscription from "../../hooks/useMissionFlightsSubscription.jsx"
import TimeDisplay from "../../components/TimeDisplay/index.jsx";
import useIsSmallScreen from "../../hooks/useIsSmallScreen.jsx";

function MissionCollectList(props) {
    const { organizationId, locationId } = useParams();
    const flightsObject = useMissionFlightsSubscription(organizationId, locationId, props.mission);

    // Convert flightsObject to an array
    const flights = Object.values(flightsObject);

    return (
        <CorvusList variant="flush" className="p-0">
            {flights.map((flight) => {
                return (
                    <CorvusListItem
                        className="bg-light p-0 py-2"
                        as={Link}
                        to={urls.flight(organizationId, locationId, flight.id)}
                    >
                        <CorvusListItemText className="p-0">
                            <DroneCollectStatusMessage
                                mission={props.mission}
                                flight={flight}
                                organizationId={organizationId}
                                locationId={locationId}
                            />
                        </CorvusListItemText>
                    </CorvusListItem>
                );
            })}
        </CorvusList>
    );
}

function MissionProcessList(props) {
    const { organizationId, locationId, missionId } = useParams();
    const [apps, setApps] = React.useState([]);
    const [missionHandlerStarted, setMissionHandlerStarted] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const { getUserIsAdmin } = useUser();

    React.useEffect(() => {
        async function getCorvusApps() {
            await api.getCorvusApps(setApps);
        }
        getCorvusApps();
    }, []);

    React.useEffect(() => {
        return api.subscribeResults(organizationId, locationId, missionId, setResults);
    }, [organizationId, locationId, missionId]);

    React.useEffect(() => {
        if (props.mission.running === false)
        {
            console.log("Set mission handler stopped")
            setMissionHandlerStarted(false);
        }
    }, [props.mission.running])

    function findApp(apps, app) {
        return apps.find((a) => a.id === app.id);
    }

    function appCanRestart(apps, appId) {
        const matchingApp = apps.find(appItem => appItem.id === appId);
        console.log("app state:", matchingApp?.state);
        const state = matchingApp?.state;
        if (state) {
            return [missionApi.STATE_CANCELLED, missionApi.STATE_FAILED, missionApi.STATE_SUCCEEDED].includes(matchingApp?.state);
        }
        return true;
    }

    function getAppResults(app, results) {
        return results.filter(result => result.app.id === app.id);
    }

    return (
        <CorvusList variant="flush" className="p-0">
            {missionApi.getMissionApps(props.mission).map((app) => {
                const appResults = getAppResults(app, results);
                return (
                    <>
                        {appResults.length > 0 ? (
                            // We have results, show one listgroup item for every result
                            appResults.map((result) => (
                                <ListGroup.Item
                                    className='bg-light p-0 py-2'
                                    action={!!result.fileName} // Set action to true only if result.fileName is truthy
                                    onClick={result.fileName
                                        ? async () => {
                                            try {
                                                await api.downloadResult(organizationId, locationId, missionId, result.id, result.fileName);
                                            } catch (error) {
                                                console.error("Error downloading result:", error);
                                            }
                                        }
                                        : undefined
                                    }
                                >
                                    <CorvusListItemText className="p-0">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <AppStatusMessage
                                                mission={props.mission}
                                                app={findApp(apps, app)}
                                            />
                                            <div>
                                                {result.fileName && (<FaDownload
                                                    className="me-2 text-muted"
                                                    style={{ marginTop: "-2px" }}
                                                />)}
                                                {getUserIsAdmin() && appCanRestart(props.mission.apps, app.id) && <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        try {
                                                            api.setAppPending(organizationId, locationId, missionId, app.id)
                                                            if (!missionHandlerStarted) {
                                                                const restart = true;
                                                                const noFly = true;
                                                                api.restartMissionHandler(organizationId, locationId, missionId, restart, noFly);
                                                                setMissionHandlerStarted(true);
                                                            }
                                                        } catch (error) {
                                                            console.error("Error restarting app:", error);
                                                        }
                                                    }}
                                                >
                                                    Restart
                                                </Button>
                                                }
                                            </div>
                                        </div>
                                    </CorvusListItemText>
                                </ListGroup.Item>
                            ))
                        ) : (
                            // We have no results, show one listgroup item for the app so that progress is still visible
                            <ListGroup.Item
                                className="bg-light p-0 py-2"
                            >
                                <CorvusListItemText className="p-0">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <AppStatusMessage
                                            mission={props.mission}
                                            app={findApp(apps, app)}
                                        />
                                        {getUserIsAdmin() && appCanRestart(props.mission.apps, app.id) && <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                try {
                                                    api.setAppPending(organizationId, locationId, missionId, app.id)
                                                    if (!missionHandlerStarted) {
                                                        const restart = true;
                                                        const noFly = true;
                                                        api.restartMissionHandler(organizationId, locationId, missionId, restart, noFly);
                                                        setMissionHandlerStarted(true);
                                                    }
                                                } catch (error) {
                                                    console.error("Error restarting app:", error);
                                                }
                                            }}
                                        >
                                            Restart
                                        </Button>
                                        }
                                    </div>
                                </CorvusListItemText>
                            </ListGroup.Item>
                        )}
                    </>
                );
            })}
            {/* {getUserIsAdmin() && (
                <ListGroup.Item className="bg-light py-4 px-4">
                    <InputGroup>
                        <AppSelector value={appIdToAdd} onChange={setAppIdToAdd} />
                        <Button variant='success' onClick={addApp}>
                            <FaPlus className='me-2' />
                            Add
                        </Button>
                    </InputGroup>
                </ListGroup.Item>
            )} */}
        </CorvusList >
    );
}

function MissionUploadList(props) {
    const { organizationId, locationId, missionId } = useParams();
    const [measurementUrls, setMeasurementUrls] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                let measurements = props.mission?.measurements || [];
                measurements = measurements.slice(Math.max(measurements.length - 4, 0));

                const urls = await Promise.all(measurements.map((measurement) => {
                    return getFirebaseStorageDownloadUrl(`organizations/${organizationId}/locations/${locationId}/measurements/512x512/${measurement.id}_512x512.jpg`);
                }));

                setMeasurementUrls(urls);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error if needed
            }
        };

        fetchData();
    }, [props.mission, locationId, organizationId]);

    return (
        <CorvusList variant="flush" className="p-0">
            <ListGroup.Item
                as={Link}
                to={urls.missionMeasurements(organizationId, locationId, missionId)}
                action={true}
                className={classnames(
                    "bg-light px-4 py-2",
                    props.className
                )}
            >
                <div
                    onClick={props.onClick}
                    className={classnames("d-flex justify-content-between align-items-start", props.className)}
                >
                    <Row>
                        {measurementUrls.length > 0 &&
                            measurementUrls.map((measurementUrl) => (
                                <Col xs={3}>
                                    <Figure.Image src={measurementUrl} rounded />
                                </Col>
                            ))}
                        {measurementUrls.length === 0 && "No measurements"}
                    </Row>
                </div>
            </ListGroup.Item>
        </CorvusList>
    );
}

function MissionCollectAccordionItem(props) {
    return (
        <Accordion.Item eventKey={props.eventKey}>
            <Accordion.Header>
                <CollectStatusMessage mission={props.mission} />
            </Accordion.Header>
            <Accordion.Body className="p-0">
                <MissionCollectList mission={props.mission} />
            </Accordion.Body>
        </Accordion.Item>
    );
}

function MissionUploadAccordionItem(props) {
    return (
        <Accordion.Item eventKey={props.eventKey}>
            <Accordion.Header>
                <UploadStatusMessage mission={props.mission} />
            </Accordion.Header>
            <Accordion.Body className="p-0">
                <MissionUploadList mission={props.mission} />
            </Accordion.Body>
        </Accordion.Item>
    );
}

function MissionProcessAccordionItem(props) {
    return (
        <Accordion.Item eventKey={props.eventKey}>
            <Accordion.Header>
                <ProcessingStatusMessage mission={props.mission} />
            </Accordion.Header>
            <Accordion.Body className="p-0">
                <MissionProcessList mission={props.mission} />
            </Accordion.Body>
        </Accordion.Item>
    );
}

function MissionActivityButton(props) {
    const missionActivity = missionApi.getMissionActivity(props.mission);

    function getColor() {
        switch (missionActivity) {
            case missionApi.STATE_PENDING:
            case missionApi.STATE_CANCELLED:
            case 'waiting':
                return 'secondary';
            case missionApi.STATE_SUCCEEDED:
            case 'finished':
                return 'success';
            case missionApi.STATE_FAILED:
                return 'danger';
            default:
                return 'primary'
        }
    }

    return (
        <Button
            variant={getColor()}
            className={props.className}
            size='sm'
        >
            {missionActivity}
        </Button>
    );
}


function MissionAccordion(props) {
    return (
        <Accordion className="mb-4" defaultActiveKey={missionApi.getMissionActivity(props.mission)}>
            <MissionCollectAccordionItem
                mission={props.mission}
                eventKey="collecting"
            />
            <MissionUploadAccordionItem
                mission={props.mission}
                eventKey="uploading"
            />
            <MissionProcessAccordionItem
                mission={props.mission}
                eventKey="processing"
            />
        </Accordion>
    );
}

function MissionLog(props) {
    return (
        <>
            {props.events.slice().reverse().map((event, index) => (
                <ListGroup.Item className='px-4 py-2'>
                    <div>
                        <b>{props.events.length - index}.{' '}{event.type}</b>
                    </div>
                    <div className="ms-4">
                        <code>timestamp{': '}</code>
                        <code>
                            {Moment.unix(event.timestamp.seconds).format('YYYY-MM-DD HH:mm:ss:SSS')}
                        </code>
                    </div>
                    {Object.keys(event).filter(key => !['type', 'timestamp'].includes(key)).sort().map((key) => (
                        <div className="ms-4">
                            <code>{key}{': '}</code><code>{JSON.stringify(event[key])}</code>
                        </div>
                    ))}
                </ListGroup.Item>
            ))}
        </>
    );
}

export function MissionHandlerAlerts({ organizationId, locationId, missionId, heading }) {
    const [alerts, setAlerts] = React.useState([]);
    const [showAllAlerts, setShowAllAlerts] = React.useState(false);
    const [location, setLocation] = React.useState(undefined);
    const { getUserIsAdmin } = useUser();
    const isSmallScreen = useIsSmallScreen();

    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    const levelToVariantMap = {
        info: 'primary',
        warning: 'warning',
        warn: 'warning',
        error: 'danger',
    };

    function decodeHtmlEntities(str) {
        console.log(str);
        return str.replace("<br>", "<br>");
    }

    function toggleShowAllAlerts() {
        setShowAllAlerts(!showAllAlerts);
    }

    function hasAlerts() {
        return alerts.length > 0;
    }

    // update the allerts when new alerts come
    React.useEffect(() => {
        return api.subscribeMission(organizationId, locationId, missionId, (missionDoc) => {
            if (missionDoc.mission_handler && missionDoc.mission_handler.alerts) {
                missionDoc.mission_handler.alerts.map((alert) => {
                    return {
                        ...alert,
                        message: decodeHtmlEntities(alert.message)
                    }
                })
                setAlerts(missionDoc.mission_handler.alerts);
            }

        });
    }, [organizationId, locationId, missionId]);


    return (hasAlerts() &&
        <Card.Body className="p-4 pt-0">
            {showAllAlerts
                ? alerts.slice().reverse().map((alert, index) => (
                    <Alert
                        key={index}
                        variant={levelToVariantMap[alert.level] || 'primary'}
                        className={`mb-1 ${index !== 0 ? 'pb-1 pt-1' : ''}`}
                    >
                        {heading && index === 0 &&
                            <Alert.Heading style={{ fontSize: '1rem', lineHeight: '0.5' }}>{heading}</Alert.Heading>}
                        <div className="d-flex justify-content-between align-items-center">
                            <span dangerouslySetInnerHTML={{ __html: alert.message }} />
                            <div className="d-flex align-items-center">
                                <Badge bg="secondary" className="ms-2">
                                    <TimeDisplay
                                        timestamp={alert.date.seconds}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        hideDateIfToday={true}
                                        breakOnSpace={isSmallScreen}
                                        location={location}
                                    />
                                </Badge>
                                {getUserIsAdmin() && index === 0 &&
                                    <Button
                                        variant="link"
                                        onClick={toggleShowAllAlerts}
                                        className="p-0"
                                    >
                                        <FaAngleUp color="grey" className="ms-3" />
                                    </Button>}
                            </div>
                        </div>
                    </Alert>
                ))
                : (
                    <Alert
                        variant={levelToVariantMap[alerts[alerts.length - 1].level] || 'primary'}
                        className='m-0'
                    >
                        {heading &&
                            <Alert.Heading style={{ fontSize: '1rem', lineHeight: '0.5' }}>{heading}</Alert.Heading>}
                        <div className="d-flex justify-content-between align-items-center">
                            <span dangerouslySetInnerHTML={{ __html: alerts[alerts.length - 1].message }} />
                            <div className="d-flex align-items-center">
                                <Badge bg="secondary" className="ms-2">
                                    <TimeDisplay
                                        timestamp={alerts[alerts.length - 1].date.seconds}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        hideDateIfToday={true}
                                        breakOnSpace={isSmallScreen}
                                        location={location}
                                    />
                                </Badge>
                                {getUserIsAdmin() && <Button
                                    variant="link"
                                    onClick={toggleShowAllAlerts}
                                    className="p-0"
                                >
                                    <FaAngleDown color="grey" className="ms-3" />
                                </Button>}
                            </div>
                        </div>
                    </Alert>
                )
            }
        </Card.Body>
    );
}

export default function MissionPage() {
    const { organizationId, locationId, missionId } = useParams();
    const [view, setView] = React.useState('default');
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
    const [showEvents, setShowEvents] = React.useState(false);
    const [showResumeModal, setShowResumeModal] = React.useState(false);
    const [showAbortModal, setShowAbortModal] = React.useState(false);
    const [feedbacks, setFeedbacks] = React.useState([]);
    const [mission, setMission] = React.useState(undefined);
    const [results, setResults] = React.useState([]);
    const [apps, setApps] = React.useState([]);
    const { getUserIsAdmin } = useUser();
    const history = useHistory();

    function resumeMission() {
        setShowResumeModal(false);
        api.storeResumeEvent(organizationId, locationId, missionId);
        const restart = false;
        const noFly = false;
        return api.restartMissionHandler(organizationId, locationId, missionId, restart, noFly);
    }

    function abortMission() {
        setShowAbortModal(false);
        api.storeAbortMissionEvent(organizationId, locationId, missionId);
    }

    function deleteMission() {
        return api.deleteMission(organizationId, locationId, missionId);
    }

    React.useEffect(() => {
        let unsubscribeMission;

        if (organizationId && locationId && missionId) {
            unsubscribeMission = api.subscribeMission(organizationId, locationId, missionId, setMission);
        } else if (!organizationId && !locationId && missionId) {
            unsubscribeMission = api.subscribeMission2(missionId, (mission) => {
                history.replace(urls.locationMission(mission.organizationId, mission.locationId, missionId));
            });
        }

        // Cleanup function for unsubscribing when the component unmounts or when missionId changes
        return () => {
            // Unsubscribe from the mission
            if (unsubscribeMission) {
                unsubscribeMission();
            }

            setMission(undefined);
        };
    }, [organizationId, locationId, missionId, history]);

    // Load the mission feedback
    React.useEffect(() => {
        return api.subscribeMissionFeedbacks(organizationId, locationId, missionId, (missionFeedbacks) => {
            // We get a list of feedback entries that contains a key 'messages' which holds a list of objects with properties 'keyword' and 'description' here. We want to call setMissionFeedbacks with a list of object with all only the keywords as string (so not as object). The list of strings should also only contain unique values. Like this: [{"messages": [{"keyword": "keyword_1"}, {"keyword": "keyword_2"}]}]. So in this case we want to call setFeedbacks with (['keyword_1', 'keyword_2']])
            setFeedbacks(missionFeedbacks.map((f) => f.messages.map((m) => m.keyword)).flat());
        });
    }, [organizationId, locationId, missionId]);

    React.useEffect(() => {
        return api.subscribeResults(organizationId, locationId, missionId, setResults);
    }, [organizationId, locationId, missionId]);

    React.useEffect(() => {
        async function getCorvusApps() {
            await api.getCorvusApps(setApps);
        }
        getCorvusApps();
    }, []);

    function toggleShowEvents() {
        setShowEvents(!showEvents);
    }

    function getMissionDrones(mission) {
        // TODO : This would be cleaner since we don't need the drone array. That array is solely used for this purpose. Not all mission.flights have drone id set currently.
        // const allDrones = mission.flights.map((flight) => flight.drone.id);
        const allDrones = missionApi.getMissionDrones(mission).map((drone) => drone.id);
        return [...new Set(allDrones)];
    }

    function getFirestorePath() {
        return firestoreApi.getMissionDocumentPath(organizationId, locationId, missionId);
    }

    function findApp(apps, app) {
        return apps.find((a) => a.id === app.id);
    }

    function getAppResults(app, results) {
        return results.filter(result => result.app.id === app.id);
    }

    if (!mission) {
        return <></>;
    }

    return (
        <Layout>
            <MissionBreadcrumbContainer />

            <Modal show={showResumeModal} onHide={() => setShowResumeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Resume Mission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Resume{' '}mission{' '}now?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        onClick={() => setShowResumeModal(false)}
                    >
                        Cancel
                    </Button>
                    <Dropdown
                        as={ButtonGroup}
                        align={{ lg: 'start' }}
                    >
                        <Button
                            variant="primary"
                            onClick={resumeMission}
                        >
                            <FaPlay
                                className="me-2"
                                style={{ marginTop: "-2px" }}
                            />
                            Resume
                        </Button>
                        <Dropdown.Toggle split variant="primary" />
                        <Dropdown.Menu
                        >
                            <Dropdown.Item
                                as={Link}
                                target="_blank"
                                to={`${urls.locationMap(organizationId, locationId)}?missionId=${missionId}&planner=v2`}
                            >
                                <FaMap
                                    className="me-2"
                                    style={{ marginTop: "-2px" }}
                                />
                                Preview
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Footer>
            </Modal>

            <Modal show={showAbortModal} onHide={() => setShowAbortModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Abort Mission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Abort{' '}mission{' '}now?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        onClick={() => setShowAbortModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={abortMission}
                    >
                        <FaTimes
                            className="me-2"
                            style={{ marginTop: "-2px" }}
                        />
                        Abort
                    </Button>
                </Modal.Footer>
            </Modal>

            <FeedbackModal
                name='Mission'
                firestoreDocumentPath={getFirestorePath()}
                isVisible={showFeedbackModal}
                setIsVisible={setShowFeedbackModal}
            />

            <Card className="mb-4">
                <Title title={
                    <>
                        <BackButton href={urls.locationMissions(organizationId, locationId)} />
                        <TimeDisplay
                            timestamp={mission.timestamp.seconds}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={organizationId}
                            locationId={locationId}
                        />
                    </>
                }>
                    <div>
                        <MapButton
                            url={`${urls.locationMap(organizationId, locationId)}?missionId=${missionId}`}
                            className="ms-2"
                        />
                        <ButtonGroup className="ms-2">
                            {getUserIsAdmin() && (
                                <DevMenuButton>
                                    <Dropdown.Item
                                        as={Link}
                                        to={`${urls.locationMap(organizationId, locationId)}?missionId=${missionId}`}
                                    >
                                        <FaMap
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        View Map
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={toggleShowEvents}>
                                        <FaCalendar
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        {showEvents ? 'Hide Events' : 'View Events'}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setView(view === 'default' ? 'data' : 'default')}>
                                        <FaCode
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        {view === 'default' && <>View Data</>}
                                        {view === 'data' && <>View Default</>}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <FirestoreDevMenuItem
                                        as={Dropdown.Item}
                                        path={firestoreApi.getMissionDocumentPath(organizationId, locationId, missionId)}
                                    />
                                    <StorageDevMenuItem
                                        as={Dropdown.Item}
                                        path={firestoreApi.getMissionDocumentPath(organizationId, locationId, missionId)}
                                    />
                                    <LogDevMenuItem
                                        as={Dropdown.Item}
                                        name='mission'
                                        value={missionId}
                                    />
                                </DevMenuButton>
                            )}
                            <DropdownButton
                                variant="outline-secondary"
                                align="end"
                                as={ButtonGroup}
                                title='Mission'
                            >
                                {getUserIsAdmin() && (
                                    <>
                                        <Dropdown.Item
                                            onClick={() => setShowAbortModal(true)}
                                        >
                                            <FaTimes
                                                className="me-2"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            Abort
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => setShowResumeModal(true)}
                                        >
                                            <FaPlay
                                                className="me-2"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            Resume
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => setShowFeedbackModal(true)}
                                        >
                                            <FaComment
                                                className="me-2"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            Feedback
                                        </Dropdown.Item>
                                    </>
                                )}
                                <Dropdown.Item
                                    className="text-danger"
                                    variant="outline-danger"
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    <FaTrash
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Delete
                                </Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>
                    </div>
                </Title>
                <MissionHandlerAlerts
                    organizationId={organizationId}
                    locationId={locationId}
                    missionId={missionId}
                />
                <ListGroup variant="flush">
                    <ListGroup.Item className="px-4">
                        <Row>
                            <Col xs={12} md={3} className="d-flex align-items-center">
                                <b>Status</b>
                            </Col>
                            <Col xs={12} md={9}>
                                <h5 className='d-inline'>
                                    <MissionActivityButton
                                        className="me-2"
                                        mission={mission}
                                    />
                                    <Button
                                        variant={mission.measurements?.length > 0 ? 'secondary' : 'outline-secondary'}
                                        as={Link}
                                        to={urls.missionMeasurements(organizationId, locationId, missionId)}
                                        size='sm'
                                        className="me-2"
                                    >
                                        <FaImage className="me-2" />
                                        <MeasurementInfoString mission={mission} />
                                    </Button>
                                    {getUserIsAdmin() && feedbacks.length > 0 && (
                                        <Button
                                            variant='danger'
                                            as={Link}
                                            size="sm"
                                        >
                                            <FaComment className="me-2" />
                                            {feedbacks.length}
                                        </Button>
                                    )}
                                </h5>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-4">
                        <Row>
                            <Col xs={12} md={3} className="d-flex align-items-center">
                                <b>Task</b>
                            </Col>
                            <Col xs={12} md={9}>
                                <h5 className='d-inline'>
                                    <Button
                                        variant='secondary'
                                        size='sm'
                                        as={Link}
                                        to={urls.task(organizationId, locationId, mission.task.id)}
                                    >
                                        {mission?.task?.name}
                                    </Button>
                                </h5>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-4">
                        <Row>
                            <Col xs={12} md={3} className="d-flex align-items-center">
                                <b>Drones</b>
                            </Col>
                            <Col xs={12} md={9}>
                                <h5 className='d-inline'>
                                    {getMissionDrones(mission).map((droneId) => {
                                        return (
                                            <Button
                                                key={droneId}
                                                bg='secondary'
                                                variant='secondary'
                                                size='sm'
                                                className='me-1 py-1 weight-bold'
                                                as={Link}
                                                to={urls.locationDrone(organizationId, locationId, droneId)}
                                            >
                                                {droneId}
                                            </Button>
                                        );
                                    })}
                                </h5>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {missionApi.getMissionApps(mission).map((app) => {
                        const appResults = getAppResults(app, results);
                        const appName = findApp(apps, app)?.name;
                        return (
                            <>
                                {
                                    // Show download button for every result of which the fileName is set
                                    appResults.map((result) => (
                                        result.fileName &&
                                        <ListGroup.Item className="px-4">
                                            <Row>
                                                <Col xs={12} md={3} className="d-flex align-items-center">
                                                    <b>{appName ? `Results from ${appName}` : `Results`}</b>
                                                </Col>
                                                <Col xs={12} md={9}>
                                                    <h5 className='d-inline'>
                                                        <Button
                                                            key={result.id}
                                                            variant='outline-secondary'
                                                            size='sm'
                                                            className='me-1 py-1 weight-bold'
                                                            onClick={() => api.downloadResult(organizationId, locationId, missionId, result.id, result.fileName)}
                                                        >
                                                            <FaDownload className="me-2" />
                                                            Download
                                                        </Button>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))
                                }
                            </>
                        );
                    })}
                    {getUserIsAdmin() && (
                        <>
                            {feedbacks.length > 0 && (
                                <ListGroup.Item className="px-4">
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <b>Feedbacks</b>
                                        </Col>
                                        <Col xs={12} md={9}>
                                            <h5 className='d-inline'>
                                                {feedbacks.map((feedback, index) => {
                                                    return (
                                                        <Badge
                                                            key={index}
                                                            bg='danger'
                                                            className='me-1'
                                                        >
                                                            {feedback !== 'comment' ? feedback : 'other'}
                                                        </Badge>
                                                    );
                                                })}
                                            </h5>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )}
                        </>
                    )}
                    {showEvents && (
                        <MissionLog events={mission.events} />
                    )}
                    {view === 'data' && (
                        <ListGroup.Item className='px-4'>
                            <CodeBlock name='Mission' code={mission} />
                        </ListGroup.Item>
                    )}
                </ListGroup>
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Mission</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure to delete mission <b>{<TimeDisplay
                            timestamp={mission.timestamp.seconds}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={organizationId}
                            locationId={locationId}
                        />}</b>?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-secondary"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => deleteMission().then(() =>
                                history.push(urls.locationMissions(organizationId, locationId))
                            )}
                        >
                            <FaTrash
                                className="me-2"
                                style={{ marginTop: "-2px" }}
                            />
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card>
            {view === 'default' && (
                <MissionAccordion mission={mission} />
            )}
            {/* <FeedbackWizzard mission={mission} /> */}
        </Layout >
    );
}
