import moment from 'moment-timezone';
import React from "react";
import {
    Alert, Badge, Button, ButtonGroup, Card, Col, Dropdown, DropdownButton,
    ListGroup, Modal, Row
} from "react-bootstrap";
import { FaAnchor, FaCamera, FaChevronRight, FaClock, FaCodeBranch, FaCog, FaCube, FaGlasses, FaMap, FaPlay, FaPowerOff, FaRedo, FaQrcode, FaServer, FaSkull, FaHome, FaPause } from "react-icons/fa";
import { BsAlarmFill } from "react-icons/bs";
import { TbZzz } from "react-icons/tb";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import SlideToUnlock from "../../components/SlideToUnlock";
import DevMenuButton from "../../components/DevMenuButton";
import DroneAccuracyStat from '../../components/DroneAccuracyStat';
import DroneBatteryStat from '../../components/DroneBatteryStat';
import DroneStateStat from '../../components/DroneStateStat';
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import Layout, { DroneBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import LogDevMenuItem from "../../components/LogDevMenuItem";
import MissionList from '../../components/MissionList';
import { MissionHandlerAlerts } from '../MissionPage/index.jsx';
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import TimeDisplay from '../../components/TimeDisplay/index.jsx';
var parser = require("cron-parser");

function CorvusAlert(props) {
    return (
        <>
            {props.alert.active && (
                <>
                    {props.alert.level === "warning" && (
                        <Alert variant="warning" className={props.className}>
                            <ul className="m-0">
                                {props.alert.message}
                            </ul>
                        </Alert>
                    )}
                    {props.alert.level === "info" && (
                        <Alert
                            dismissible
                            variant="info"
                            className={props.className}
                            onClose={() => {
                                api.deactivateAlert(props.drone.id, props.alert);
                            }}
                        >
                            <ul className="m-0">
                                {props.alert.message}
                            </ul>
                        </Alert>
                    )}
                    {props.alert.level === "error" && (
                        <Alert
                            dismissible
                            variant="danger"
                            className={props.className}
                            onClose={() => {
                                api.deactivateAlert(props.drone.id, props.alert);
                            }}
                        >
                            <ul className="m-0">
                                {props.alert.message}
                            </ul>
                        </Alert>
                    )}
                </>
            )}
        </>
    );
}

function ErrorMessage(props) {
    function onCloseErrors() {
        api.closeErrors(props.droneId);
    }

    return (
        <Alert
            className={props.className}
            variant="danger"
            onClose={onCloseErrors}
            dismissible
        >
            <ul className="m-0">
                {props.errors &&
                    [...new Set([...Object.keys(props.errors)].map((error) => error))]
                        .reverse()
                        .map((error) => {
                            return <li>{error}</li>;
                        })}
            </ul>
        </Alert>
    );
}

export function DroneMissionList(props) {
    const { organizationId, locationId, droneId } = useParams();
    const [missions, setMissions] = React.useState([]);
    const [routines, setRoutines] = React.useState([]);
    const [timezone, setTimezone] = React.useState('UTC');

    React.useEffect(() => {
        if (organizationId && locationId && droneId) {
            return api.subscribeDroneMissions(organizationId, locationId, droneId, setMissions, props.limit);
        }
    }, [organizationId, locationId, droneId, props.limit]);

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getTimezone(organizationId, locationId).then((timezone) => setTimezone(timezone));
            api.subscribeRoutines(organizationId, locationId, setRoutines);
        }
    }, [organizationId, locationId]);

    return (
        <MissionList
            className={props.className}
            routines={routines}
            organizationId={organizationId}
            locationId={locationId}
            missions={missions}
        >
            {routines.filter(routine => routine.enabled && routine.droneId === droneId).sort((a, b) => {
                try {
                    const aNext = parser.parseExpression(a.interval, { tz: timezone }).next().toDate();
                    const bNext = parser.parseExpression(b.interval, { tz: timezone }).next().toDate();
                    return aNext - bNext;
                } catch {
                    return 0;
                }
            }).map(routine => {
                let routineTimeDisplay;
                try {
                    routineTimeDisplay = <TimeDisplay
                        timestamp={moment(parser.parseExpression(routine.interval, { tz: timezone }).next().toDate()).unix()}
                        format="ddd HH:mm"
                        organizationId={organizationId}
                        locationId={locationId}
                    />
                } catch {
                    routineTimeDisplay = "Invalid";
                }

                return <ListGroup.Item
                    className="px-4 py-2 d-flex justify-content-between align-items-start"
                    key={props.key}
                    as={Link}
                    to={urls.routine(organizationId, locationId, routine.id)}
                    action
                >
                    <div>
                        <FaClock className='me-4 text-primary' style={{ marginTop: "-2px" }} />
                        {routine.name}
                    </div>
                    <div>
                        <Badge pill bg='primary' className='me-1'>
                            <FaClock className='me-2' style={{ marginTop: "-2px" }} />
                            {routineTimeDisplay}
                        </Badge>
                        <FaChevronRight style={{ color: "#b9b9b9" }} />
                    </div>
                </ListGroup.Item>
            })}
        </MissionList>
    );
}

function DroneEditButton(props) {
    return (
        <Button
            variant={props.variant}
            as={Link}
            to={urls.locationDroneSettings(props.drone.organizationId, props.drone.locationId, props.drone.id)}
        >
            <FaCog
                className="me-2"
                style={{
                    marginTop: "-2px",
                }}
            />
            Settings
        </Button>
    );
}

// function DroneStopButton(props) {
//     return (
//         <Button
//             variant="danger"
//             onClick={() => console.log('Stopping drone')}
//         >
//             <FaStop
//                 className="me-2"
//                 style={{
//                     marginTop: "-2px",
//                 }}
//             />
//             Stop
//         </Button>
//     );
// }

function DroneStartButton(props) {
    return (
        <Button
            variant="primary"
            as={Link}
            to={urls.droneStart(props.drone.organizationId, props.drone.locationId, props.drone.id)}
        >
            <FaPlay
                className="me-2"
                style={{
                    marginTop: "-2px",
                }}
            />
            Start
        </Button>
    );
}

function AdminDroneButton(props) {
    const { getUserIsAdmin } = useUser();

    function thisDroneIsOnline() { // TODO : make dry
        if (!props.drone) return false;
        if (props.drone.augmentation === 'SIMULATION') return true;
        if (props.drone.augmentation === 'EMULATION') return true;

        return api.droneIsOnline(props.droneFeedback);
    }

    return (
        <>
            {getUserIsAdmin() && (
                <DevMenuButton>
                    <Dropdown.Item
                        as={Link}
                        to={urls.locationDroneNerd(props.drone.organizationId, props.drone.locationId, props.drone.id)}
                    >
                        <FaGlasses
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        View State
                    </Dropdown.Item>
                    <Dropdown.Item
                        as={Link}
                        to={urls.locationDroneLivestream(props.drone.organizationId, props.drone.locationId, props.drone.id)}
                    >
                        <FaCamera
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Livestream
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <FirestoreDevMenuItem
                        text='Config'
                        as={Dropdown.Item}
                        path={`droneConfigs/${props.drone.id}`}
                    />
                    <FirestoreDevMenuItem
                        text='State'
                        as={Dropdown.Item}
                        path={`droneFeedbacks/${props.drone.id}`}
                    />
                    <FirestoreDevMenuItem
                        text='Commands'
                        as={Dropdown.Item}
                        path={`droneCommands/${props.drone.id}`}
                    />
                    <LogDevMenuItem
                        as={Dropdown.Item}
                        name='drone'
                        value={props.drone.id}
                    />
                </DevMenuButton>
            )}
            <ButtonGroup>

                {/* draw the start button or the settings button */}
                {thisDroneIsOnline() && !api.droneHasActiveMissions(props.droneFeedback) && (
                    <DroneStartButton drone={props.drone} />
                )}
                {(!thisDroneIsOnline() || api.droneHasActiveMissions(props.droneFeedback)) &&
                    <DroneEditButton drone={props.drone} variant={thisDroneIsOnline() ? "primary" : "secondary"} />
                }
                {/* draw the drop down button button, gray or blue, full options or  */}
                {!thisDroneIsOnline() && (
                    <>
                        <DropdownButton
                            variant={"secondary"}
                            align="end"
                            as={ButtonGroup}
                        >
                            <Dropdown.Item
                                as={Link}
                                to={urls.payloadSettings(props.drone.payloadId)}
                            >
                                <FaCamera
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Payload settings
                            </Dropdown.Item>
                        </DropdownButton>
                    </>
                )}
                {thisDroneIsOnline() && (
                    <DropdownButton
                        variant={thisDroneIsOnline() ? "primary" : "secondary"}
                        align="end"
                        as={ButtonGroup}
                    >
                        <>
                            <Dropdown.Item
                                as={Link}
                                to={urls.locationDroneSettings(props.drone.organizationId, props.drone.locationId, props.drone.id)}
                            >
                                <FaCog
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Settings
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={urls.payloadSettings(props.drone.payloadId)}
                            >
                                <FaCamera
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Payload settings
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => { props.setShowShutdownModal(true) }}
                            >
                                <FaPowerOff
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Shutdown
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => { props.setShowRebootModal(true) }}
                            >
                                <FaRedo
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Reboot
                            </Dropdown.Item>
                            {props.droneFeedback?.widgets?.status?.data?.toLowerCase() !== 'sleeping' && (
                                <Dropdown.Item
                                    onClick={() => { api.sendGoToSleep(props.drone.id); }}
                                >
                                    <TbZzz
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Go to sleep
                                </Dropdown.Item>)}
                            {props.droneFeedback?.widgets?.status?.data?.toLowerCase() === 'sleeping' && (
                                <Dropdown.Item
                                    onClick={() => { api.sendWakeUp(props.drone.id); }}
                                >
                                    <BsAlarmFill
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Wake up
                                </Dropdown.Item>)}
                        </>
                    </DropdownButton>
                )}
            </ButtonGroup>
        </>
    );
}

function UserDroneButton(props) {

    function thisDroneIsOnline() { // TODO : make dry
        if (!props.drone) return false;
        if (props.drone.augmentation === 'SIMULATION') return true;
        if (props.drone.augmentation === 'EMULATION') return true;

        return api.droneIsOnline(props.droneFeedback);
    }

    return (
        <>
            {thisDroneIsOnline() && (
                <ButtonGroup>
                    {/* draw the start button or the settings button */}
                    {thisDroneIsOnline() && !api.droneHasActiveMissions(props.droneFeedback) && (
                        <DroneStartButton drone={props.drone} />
                    )}
                    {(!thisDroneIsOnline() || api.droneHasActiveMissions(props.droneFeedback)) &&
                        <DroneEditButton drone={props.drone} variant={thisDroneIsOnline() ? "primary" : "secondary"} />
                    }
                    <DropdownButton
                        variant={thisDroneIsOnline() ? "primary" : "secondary"}
                        align="end"
                        as={ButtonGroup}
                    >
                        {thisDroneIsOnline() && (
                            <>
                                <Dropdown.Item
                                    as={Link}
                                    to={urls.locationDroneSettings(props.drone.organizationId, props.drone.locationId, props.drone.id)}
                                >
                                    <FaCog
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Settings
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => { props.setShowShutdownModal(true) }}
                                >
                                    <FaPowerOff
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Shutdown
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => { props.setShowRebootModal(true) }}
                                >
                                    <FaRedo
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Reboot
                                </Dropdown.Item>
                            </>
                        )}
                    </DropdownButton>
                </ButtonGroup>
            )}
            {!thisDroneIsOnline() && (
                <DroneEditButton drone={props.drone} />
            )}
        </>
    );
}

export function DroneStatus({ droneStatus }) {
    return <>
        {!!droneStatus && (
            <Alert variant='primary' className="px-4 m-0">
                <ul className='m-0 px-4'>
                    {
                        droneStatus.data.split(';').map((status) => {
                            return (
                                <li>{status}</li>
                            )
                        })
                    }
                </ul>
            </Alert>
        )}
    </>
}

function Drone(props) {
    const [alertLevelToAlerts, setAlertLevelToAlerts] = React.useState({});
    const [alerts, setAlerts] = React.useState([]);
    const [allAlerts, setAllAlerts] = React.useState([]);
    const [showShutdownModal, setShowShutdownModal] = React.useState(false);
    const [showRebootModal, setShowRebootModal] = React.useState(false);
    const [showKillModal, setShowKillModal] = React.useState(false);
    const [showReturnToHomeModal, setShowReturnToHomeModal] = React.useState(false);
    const [showEmergencyModal, setShowEmergencyModal] = React.useState(false);
    const [isEmergencyLanding, setIsEmergencyLanding] = React.useState(false);
    const [dock, setDock] = React.useState(null);
    const { getUserIsAdmin } = useUser();
    const history = useHistory();

    React.useEffect(() => {
        return api.subscribeAllAlerts(setAllAlerts);
    }, []);

    React.useEffect(() => {
        return api.subscribeNewDroneAlerts(props.drone.id, (customAlerts) => {
            const completeAlerts = customAlerts
                .map((customAlert) => {
                    const fullAlert = allAlerts.find((a) => a.id === customAlert.id);
                    return { ...fullAlert, ...customAlert };
                });
            setAlerts(completeAlerts);
        });
    }, [allAlerts, props.drone.id]);

    // Load dock
    React.useEffect(() => {
        api.getDock(props.organizationId, props.locationId, props.drone.dockId, setDock);
    }, [props.organizationId, props.locationId, props.drone.dockId, props.drone.id])

    React.useEffect(() => {
        return api.subscribeDroneAlerts(props.drone.id, (alertsDoc) => {

            if (!alertsDoc) return {};
            if (!alertsDoc.alerts) return {};

            const alerts = alertsDoc.alerts;

            const formattedAlerts = {};
            for (const alertLevel in alerts) {
                for (const alertId in alerts[alertLevel]) {
                    const alert = alerts[alertLevel][alertId];
                    if (!formattedAlerts[alertLevel]) {
                        formattedAlerts[alertLevel] = [];
                    }
                    formattedAlerts[alertLevel].push({
                        level: alertLevel,
                        id: alertId,
                        active: alert.active,
                        owner: alert.owner,
                        message: alert.message,
                        datetime: alert.datetime,
                    });
                }
            }

            setAlertLevelToAlerts(formattedAlerts);
        });
    }, [props.drone.id])

    React.useEffect(() => {
        // Reset state when drone stops flying
        if (!thisDroneIsFlying()) {
            setIsEmergencyLanding(false);
            setShowEmergencyModal(false);
            setShowKillModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.drone, props.droneFeedback]);

    function thisDroneIsOnline() {
        if (!props.drone) return false;
        if (props.drone.augmentation === 'SIMULATION') return true;
        if (props.drone.augmentation === 'EMULATION') return true;

        return api.droneIsOnline(props.droneFeedback);
    }

    function thisDroneIsFlying() {
        if (!props.drone) return false;
        if (!props.droneFeedback) return false;
        if (!props.droneFeedback.widgets) return false;

        return api.droneIsFlying(props.droneFeedback.widgets);
    }

    function anyState() {
        return Object.keys(props.droneFeedback || {}).length !== 0;
    }

    function anyErrors() {
        // Errors are the old Alerts, will be deprecated soon.
        if (!props.droneFeedback) return false;
        if (!props.droneFeedback.errors) return false;
        return Object.keys(props.droneFeedback.errors).length > 0;
    }

    function anyAlerts() {
        if (Object.keys(alertLevelToAlerts).length === 0) return false;
        return true;
    }

    function anyMessageToDisplay() {
        if (shouldDisplayErrors()) return true;
        if (anyState()) return true;
        if (anyAlerts()) return true;
        if (anyErrors()) return true;
        return false;
    }

    function shouldDisplayErrors() {
        if (!thisDroneIsOnline()) return false;
        return alerts.some((alert) => alert.level === 'error');
    }

    function shouldDisplayWarnings() {
        if (!thisDroneIsOnline()) return false;
        if (shouldDisplayErrors()) return false;
        return alerts.some((alert) => alert.level === 'warning');
    }

    function shouldDisplayInfos() {
        if (!thisDroneIsOnline()) return false;
        if (shouldDisplayErrors()) return false;
        if (shouldDisplayWarnings()) return false;
        return alerts.some((alert) => alert.level === 'info');
    }

    function getErrorAlerts() {
        return alerts.filter((alert) => alert.level === 'error');
    }

    function getWarningAlerts() {
        return alerts.filter((alert) => alert.level === 'warning');
    }

    function getInfoAlerts() {
        return alerts.filter((alert) => alert.level === 'info');
    }

    function showReturnToHomeButton() {
        return props.droneFeedback?.widgets?.bestReturnCommand?.data !== undefined;
    }

    function showPauseButton() {
        return props.droneFeedback?.widgets?.isPaused?.data !== undefined;
    }

    function showMapButton() {
        return props.droneFeedback?.widgets?.currentFlightId?.data !== undefined;
    }

    return (
        <>
            <Modal show={showShutdownModal} onHide={() => {
                setShowShutdownModal(false)
            }}>
                <Modal.Header onClick={() => {
                    setShowShutdownModal(false)
                }}>
                    <Modal.Title>Shutdown Drone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to <b>shutdown</b> drone <b>{props.drone.id}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary-outline" onClick={() => {
                        setShowShutdownModal(false)
                    }}>
                        No
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            api.sendShutdown(props.drone.id);
                            setShowShutdownModal(false);
                        }}
                    >
                        <FaPowerOff
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRebootModal} onHide={() => {
                setShowRebootModal(false)
            }}>
                <Modal.Header onClick={() => {
                    setShowRebootModal(false)
                }}>
                    <Modal.Title>Reboot Drone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to <b>reboot</b> drone <b>{props.drone.id}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary-outline" onClick={() => {
                        setShowRebootModal(false)
                    }}>
                        No
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            api.sendReboot(props.drone.id);
                            setShowRebootModal(false);
                        }}
                    >
                        <FaRedo
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showKillModal} onHide={() => {
                setShowKillModal(false)
            }}>
                <Modal.Header onClick={() => {
                    setShowKillModal(false)
                }}>
                    <Modal.Title>Kill Drone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to <b>kill</b> drone <b>{props.drone.id}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary-outline" onClick={() => {
                        setShowKillModal(false)
                    }}>
                        No
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            api.sendKill(props.drone.id, props.droneFeedback.widgets.missions);
                            setShowKillModal(false);
                        }}
                    >
                        <FaSkull
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showReturnToHomeModal} onHide={() => {
                setShowReturnToHomeModal(false)
            }}>
                <Modal.Header onClick={() => {
                    setShowReturnToHomeModal(false)
                }}>
                    <Modal.Title>Return to home</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want <b>{props.drone.id}</b> to <b>return to home</b>? <br />
                    This will also abort the mission.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary-outline" onClick={() => {
                        setShowReturnToHomeModal(false)
                    }}>
                        No
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            api.sendAbort(props.drone.id, props.droneFeedback.widgets.missions);
                            setShowReturnToHomeModal(false);
                        }}
                    >
                        <FaHome
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEmergencyModal} onHide={() => {
                setShowEmergencyModal(false)
            }}>
                <Modal.Header onClick={() => {
                    setShowEmergencyModal(false)
                }}>
                    <Modal.Title>Emergency land Drone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to <b>emergency land</b> drone <b>{props.drone.id}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary-outline" onClick={() => {
                        setShowEmergencyModal(false)
                    }}>
                        No
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            api.sendEmergencyLand(props.drone.id, props.droneFeedback.widgets.missions);
                            setShowEmergencyModal(false);
                        }}
                    >
                        <FaSkull
                            className="me-2"
                            style={{
                                marginTop: "-2px",
                            }}
                        />
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Layout>
                <DroneBreadcrumbContainer />
                <Card>
                    <Title title={
                        <>
                            <BackButton href={urls.locationDrones(props.drone.organizationId, props.drone.locationId)} />
                            Drone {props.drone.id}
                        </>
                    }>
                        <div>
                            {getUserIsAdmin() && (
                                <AdminDroneButton
                                    drone={props.drone}
                                    droneFeedback={props.droneFeedback}
                                    setShowShutdownModal={setShowShutdownModal}
                                    setShowRebootModal={setShowRebootModal}
                                    setShowKillModal={setShowKillModal}
                                    setShowEmergencyModal={setShowEmergencyModal}
                                    setShowReturnToHomeModal={setShowReturnToHomeModal}
                                />
                            )}
                            {!getUserIsAdmin() && (
                                <UserDroneButton
                                    drone={props.drone}
                                    droneFeedback={props.droneFeedback}
                                    setShowKillModal={setShowKillModal}
                                    setShowEmergencyModal={setShowEmergencyModal}
                                    setShowShutdownModal={setShowShutdownModal}
                                    setShowRebootModal={setShowRebootModal}
                                    setShowReturnToHomeModal={setShowReturnToHomeModal}
                                />
                            )}
                        </div>
                    </Title>
                    {anyMessageToDisplay() && (
                        <Card.Body className="p-4 pt-0">
                            {shouldDisplayErrors() && (
                                <Alert
                                    dismissible
                                    variant="danger"
                                    className='m-0'
                                    onClick={() => api.deleteAlerts(props.drone.id, 'error')}
                                >
                                    <ul className='m-0'>
                                        {getErrorAlerts().map((errorAlert) => (
                                            <li>{errorAlert.message}</li>
                                        ))}
                                    </ul>
                                </Alert>
                            )}
                            {shouldDisplayWarnings() && (
                                <Alert
                                    variant="warning"
                                    className='m-0'
                                >
                                    <ul className='m-0'>
                                        {getWarningAlerts().map((warningAlert) => (
                                            <li>{warningAlert.message}</li>
                                        ))}
                                    </ul>
                                </Alert>
                            )}
                            {shouldDisplayInfos() && (
                                <Alert
                                    variant="info"
                                    className='m-0'
                                >
                                    <ul className='m-0'>
                                        {getInfoAlerts().map((infoAlert) => (
                                            <li>{infoAlert.message}</li>
                                        ))}
                                    </ul>
                                </Alert>
                            )}

                            <>
                                {Object.keys(alertLevelToAlerts).map((level) => {
                                    return alertLevelToAlerts[level].map((alert) => {
                                        return (
                                            <CorvusAlert
                                                drone={props.drone}
                                                className="m-0"
                                                alert={alert}
                                            />
                                        )
                                    })
                                })
                                }
                                {anyErrors() && (
                                    <ErrorMessage
                                        className="m-0"
                                        droneId={props.drone.id}
                                        errors={props.droneFeedback.errors}
                                    />
                                )}
                            </>
                        </Card.Body>
                    )}
                    {!!props.droneFeedback?.widgets?.missions && props.droneFeedback.widgets.missions.length > 0 &&
                        <>
                            <Card.Body className="px-4 py-0">
                                <Alert variant='warning'>Drone is in use by Mission Handler. Do not approach or touch the drone.</Alert>
                            </Card.Body>
                            {getUserIsAdmin() && (
                                props.droneFeedback.widgets.missions.map(mission => {
                                    return <MissionHandlerAlerts
                                        organizationId={mission.organizationId}
                                        locationId={mission.locationId}
                                        missionId={mission.missionId}
                                        key={mission.missionId}
                                        heading={<>Mission <TimeDisplay
                                            timestamp={Math.floor(new Date(mission.timestamp).getTime() / 1000)}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            organizationId={mission.organizationId}
                                            locationId={mission.locationId}
                                        /></>}
                                    />
                                })
                            )}
                        </>
                    }
                    {!thisDroneIsOnline() && (
                        <Card.Body>
                            <Alert className="text-center mb-0" variant="secondary">
                                <div className="text-muted">
                                    Drone is offline
                                </div>
                            </Alert>
                        </Card.Body>
                    )}
                    {thisDroneIsOnline() && (
                        <>
                            {props.droneFeedback &&
                                props.droneFeedback.widgets &&
                                Object.keys(props.droneFeedback.widgets).length >
                                0 && (
                                    <>
                                        <Card.Body className='p-4 pt-0 mt-0'>
                                            <DroneStatus
                                                droneStatus={props.droneFeedback.widgets.status}
                                            />
                                        </Card.Body>
                                        {getUserIsAdmin() && (
                                            <ListGroup variant="flush">
                                                {props.droneFeedback?.widgets?.status?.data?.toLowerCase() === 'sleeping' && !!props.droneFeedback?.widgets?.position && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>Position</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                <h5 className='d-inline'>
                                                                    <Badge bg='secondary'>Sleeping</Badge>
                                                                </h5>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                                {props.droneFeedback?.widgets?.status?.data?.toLowerCase() !== 'sleeping' && !!props.droneFeedback?.widgets?.position && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>Position</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                <h5 className='d-inline'>
                                                                    <Badge bg='danger' className="me-2">
                                                                        <span className="me-2">x</span>
                                                                        {props.droneFeedback.widgets.position.data.x.toFixed(2)}
                                                                    </Badge>
                                                                </h5>
                                                                <h5 className='d-inline'>
                                                                    <Badge bg='success' className="me-2">
                                                                        <span className="me-2">y</span>
                                                                        {props.droneFeedback.widgets.position.data.y.toFixed(2)}
                                                                    </Badge>
                                                                </h5>
                                                                <h5 className='d-inline'>
                                                                    <Badge bg='primary' className="me-2">
                                                                        <span className="me-2">z</span>
                                                                        {props.droneFeedback.widgets.position.data.z.toFixed(2)}
                                                                    </Badge>
                                                                </h5>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                                {props.droneFeedback?.widgets?.status?.data?.toLowerCase() === 'sleeping' && !!props.droneFeedback?.widgets?.orientation && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>Rotation</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                <h5 className='d-inline'>
                                                                    <Badge bg='secondary'>Sleeping</Badge>
                                                                </h5>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                                {props.droneFeedback?.widgets?.status?.data?.toLowerCase() !== 'sleeping' && !!props.droneFeedback?.widgets?.orientation && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>Rotation</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                {!props.droneFeedback.widgets.orientation.data.roll && (
                                                                    <>
                                                                        {!!props.droneFeedback.widgets.orientation.data.x && (
                                                                            <span className="me-3">
                                                                                <span className="me-2">x</span>
                                                                                {props.droneFeedback.widgets.orientation.data.x.toFixed(3)}
                                                                            </span>
                                                                        )}
                                                                        {!!props.droneFeedback.widgets.orientation.data.y && (
                                                                            <span className="me-3">
                                                                                <span className="me-2">y</span>
                                                                                {props.droneFeedback.widgets.orientation.data.y.toFixed(3)}
                                                                            </span>
                                                                        )}
                                                                        {!!props.droneFeedback.widgets.orientation.data.z && (
                                                                            <span className="me-3">
                                                                                <span className="me-2">z</span>
                                                                                {props.droneFeedback.widgets.orientation.data.z.toFixed(3)}
                                                                            </span>
                                                                        )}
                                                                        {!!props.droneFeedback.widgets.orientation.data.w && (
                                                                            <span className="me-3">
                                                                                <span className="me-2">w</span>
                                                                                {props.droneFeedback.widgets.orientation.data.w.toFixed(3)}
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {!!props.droneFeedback.widgets.orientation.data.roll && (
                                                                    <>
                                                                        {!!props.droneFeedback.widgets.orientation.data.roll && (
                                                                            <h5 className='d-inline'>
                                                                                <Badge bg='danger' className="me-2">
                                                                                    <span className="me-2">r</span>
                                                                                    {props.droneFeedback.widgets.orientation.data.roll.toFixed(1)}
                                                                                </Badge>
                                                                            </h5>
                                                                        )}
                                                                        {!!props.droneFeedback.widgets.orientation.data.pitch && (
                                                                            <h5 className='d-inline'>
                                                                                <Badge bg='success' className="me-2">
                                                                                    <span className="me-2">p</span>
                                                                                    {props.droneFeedback.widgets.orientation.data.pitch.toFixed(1)}
                                                                                </Badge>
                                                                            </h5>
                                                                        )}
                                                                        {!!props.droneFeedback.widgets.orientation.data.yaw && (
                                                                            <h5 className='d-inline'>
                                                                                <Badge bg='primary' className="me-2">
                                                                                    <span className="me-2">y</span>
                                                                                    {props.droneFeedback.widgets.orientation.data.yaw.toFixed(1)}
                                                                                </Badge>
                                                                            </h5>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                                {!!props.location && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>System</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                <span className="me-3">
                                                                    <h5
                                                                        className='d-inline'
                                                                        onClick={() => {
                                                                            history.push(urls.droneSystemList(props.drone.organizationId, props.drone.locationId, props.drone.id))
                                                                        }}
                                                                    >
                                                                        <Badge bg='primary me-2'>
                                                                            <FaCodeBranch className="me-2" />{props.drone?.version || 'latest'}
                                                                        </Badge>
                                                                    </h5>
                                                                    {props.drone.augmentation === 'SIMULATION' && (
                                                                        <h5 className='d-inline'>
                                                                            <Badge bg='info me-2'>
                                                                                <FaCube className="me-2" />
                                                                                Simulated
                                                                            </Badge>
                                                                        </h5>
                                                                    )}
                                                                    {props.drone.augmentation === 'EMULATION' && (
                                                                        <h5 className='d-inline'>
                                                                            <Badge bg='info me-2'>
                                                                                <FaServer className="me-2" />
                                                                                Emulated
                                                                            </Badge>
                                                                        </h5>
                                                                    )}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                                {!!props.location && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>Environment</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                <span className="me-3">
                                                                    {props.location?.markerType === "ARGUS" && (
                                                                        <h5 className='d-inline'>
                                                                            <Badge bg='success me-2'>
                                                                                <FaQrcode className="me-2" />
                                                                                Argus
                                                                            </Badge>
                                                                        </h5>
                                                                    )}
                                                                    {props.location?.markerType !== "ARGUS" && (
                                                                        <h5 className='d-inline'>
                                                                            <Badge bg='danger me-2'>
                                                                                <FaQrcode className="me-2" />
                                                                                Aruco
                                                                            </Badge>
                                                                        </h5>
                                                                    )}
                                                                    {props.drone.dockId.toLowerCase() !== 'none' && (
                                                                        <h5 className='d-inline'>
                                                                            <Badge bg='success me-2'>
                                                                                <FaAnchor className="me-2" />
                                                                                {props.drone.dockId === 'mobile' && ('Mobile Dock')}
                                                                                {props.drone.dockId !== 'mobile' && (dock?.name || 'Dock')}
                                                                            </Badge>
                                                                        </h5>
                                                                    )}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                                {!!props.droneFeedback.widgets.missions && props.droneFeedback.widgets.missions.length > 0 && (
                                                    <ListGroup.Item className="px-4">
                                                        <Row>
                                                            <Col xs={12} md={3}>
                                                                <b>Active missions</b>
                                                            </Col>
                                                            <Col xs={12} md={9}>
                                                                {props.droneFeedback.widgets.missions.map(mission => (
                                                                    <h5 className='d-inline'
                                                                        onClick={() => {
                                                                            history.push(urls.locationMission(mission.organizationId, mission.locationId, mission.missionId))
                                                                        }}>
                                                                        <Badge bg='success me-2'>
                                                                            <TimeDisplay
                                                                                timestamp={Math.floor(new Date(mission.timestamp).getTime() / 1000)}
                                                                                format="YYYY-MM-DD HH:mm:ss"
                                                                                organizationId={mission.organizationId}
                                                                                locationId={mission.locationId}
                                                                            />
                                                                        </Badge>
                                                                    </h5>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}
                                            </ListGroup>
                                        )}
                                    </>
                                )}
                        </>
                    )}
                </Card>
                {thisDroneIsFlying() &&
                    <>
                        {(showReturnToHomeButton() || showPauseButton() || showMapButton()) &&
                            <Row>
                                <Col xs={0} md={3} className='px-4'>
                                </Col>
                                <Col xs={12} md={9} className='px-4'>
                                    <span className="me-3">
                                        {showReturnToHomeButton() &&
                                            <Button
                                                variant="primary"
                                                className="mt-2 me-2"
                                                disabled={props.droneFeedback?.widgets?.bestReturnCommand?.data === ""}
                                                onClick={() => setShowReturnToHomeModal(true)}>
                                                <FaHome size="30px" />
                                            </Button>
                                        }
                                        {showPauseButton() &&
                                            (api.droneIsPaused(props.droneFeedback.widgets) ?
                                                <Button variant="primary" className="mt-2 me-2" onClick={() => api.sendResume(props.drone.id)}>
                                                    <FaPlay size="30px" />
                                                </Button> :
                                                <Button variant="primary" className="mt-2 me-2" onClick={() => api.sendPause(props.drone.id)}>
                                                    <FaPause size="30px" />
                                                </Button>)
                                        }
                                        {showMapButton() &&
                                            <Button
                                                variant="outline-secondary"
                                                className="mt-2 me-2"
                                                as={Link}
                                                to={`${urls.locationMap(props.drone.organizationId, props.drone.locationId)}?flightId=${props.droneFeedback.widgets.currentFlightId.data}`}
                                            >
                                                <FaMap size="30px" />
                                            </Button>
                                        }
                                    </span>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} md={3} className='px-4'>
                            </Col>
                            <Col xs={12} md={5} className='px-4'>
                                <span className="me-3">
                                    {isEmergencyLanding ?
                                        (
                                            <>
                                                <Button variant="danger" className="mt-2" onClick={() => api.sendKill(props.drone.id, props.droneFeedback.widgets.missions)}>
                                                    <FaSkull className="me-2" />
                                                    Kill
                                                </Button>
                                            </>
                                        ) : (
                                            <SlideToUnlock
                                                onUnlock={function unlock() {
                                                    console.log("drone");
                                                    console.log(props.drone);
                                                    console.log(props.droneFeedback.widgets.missions);
                                                    api.sendEmergencyLand(props.drone.id, props.droneFeedback.widgets.missions);
                                                    setIsEmergencyLanding(true);
                                                }}
                                                display="Slide to land >>"
                                            />
                                        )}
                                </span>
                            </Col>
                        </Row>
                    </>}
                {api.droneIsOnline(props.droneFeedback) && (
                    <Row>
                        <Col xs={12} md={6} className='mt-4'>
                            <DroneBatteryStat
                                battery={props.droneFeedback?.widgets?.battery?.data.toFixed() || -1}
                                offline={!api.droneIsOnline(props.droneFeedback)}
                                charging={props.droneFeedback?.widgets?.isCharging?.data || false}
                            />
                        </Col>
                        <Col xs={12} md={6} className='mt-4'>
                            <DroneAccuracyStat
                                accuracy={props.droneFeedback?.widgets?.positionAccuracy?.data.toFixed(3) || -1}
                                offline={!api.droneIsOnline(props.droneFeedback)}
                                forAdmin={getUserIsAdmin()}
                            />
                        </Col>
                        <Col xs={12} md={12} className='mt-4'>
                            <DroneStateStat
                                status={props.droneFeedback?.widgets?.status?.data || 'n/a'}
                                offline={!api.droneIsOnline(props.droneFeedback)}
                            />
                        </Col>
                    </Row>
                )}
                <DroneMissionList
                    className="mt-4"
                    limit={3}
                    droneId={props.drone.id}
                />
            </Layout >
        </>
    );
}

export default function DronePage() {
    const { droneId, organizationId, locationId } = useParams();
    const [drone, setDrone] = React.useState(undefined);
    const [location, setLocation] = React.useState(undefined);
    const [droneFeedback, setDroneFeedback] = React.useState(undefined);
    const [time, setTime] = React.useState(Date.now());
    const history = useHistory();

    if (droneId && droneId !== droneId.toUpperCase()) {
        history.replace(urls.drone(droneId.toUpperCase()))
    }

    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        if (droneId) {
            api.getDroneConfig(droneId.toUpperCase(), (drone) => {
                setDrone(drone);
                if (!locationId) {
                    history.replace(urls.locationDrone(drone.organizationId, drone.locationId, droneId.toUpperCase()));
                }
            });
            return api.subscribeDroneFeedback(droneId.toUpperCase(), setDroneFeedback);
        }
    }, [droneId, locationId, history]);

    React.useEffect(() => {
        // rerender the page every second so that when
        // drone is turned off we can detect this.
        // console.log(time); // TODO : remove dirty fix ..
        const interval = setInterval(() => setTime(Date.now()), 500);
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    if (!drone) {
        // TODO : embed in loading screen or upgrade to React 18
        return <></>;
    }

    return (
        <Drone
            organizationId={organizationId}
            locationId={locationId}
            location={location}
            drone={drone}
            droneFeedback={droneFeedback}
        />
    );
}
